<template>
  <div>
    <viewcard--c title="Moderadores">
      <b-row class="mb-1 d-flex justify-content-end">
        <b-col md="5">
          <b-input-group>
            <b-form-input
              placeholder="pesquise por Nome, Email..."
              autocomplete="off"
              v-model="search"
              @keyup.enter="filter"
            />
            <b-input-group-append>
              <b-button variant="gradient-info" @click="filter">
                Pesquisar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <view--c :permission="'permission.appointment.studio.moderator.view'" :busy="isloading">
        <Table
          :list="!isloading ? list[currentePage].itens : []"
          :fields="fields"
          responsive
          border="full"
        />
        <b-pagination
          v-model="currentePage"
          @change="getLoadMore"
          class="mt-1"
          :total-rows="rows"
          v-if="rows > 20"
          first-number
          last-number
          align="center"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </view--c>
    </viewcard--c>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { BAvatar } from 'bootstrap-vue'
import _usersService from "@/services/users-service";
export default {
  components: {
    Table,
    BAvatar
  },
  data() {
    return {
      isloading: false,
      currentePage: 1,
      search: null,
      size: 12,
      rows: 20,
      fields: [
        { key: "name", label: "Nome" },
        { key: "email", label: "Email" }
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      escalaAppImage: require('@/assets/images/svg/escalaApp.svg'),
    };
  },
  created() {
    this.getRecords(this.currentePage);
  },
  methods: {
    getRecords(_page) {
      this.isloading = true;
      _usersService
        .showModerators(_page, this.search)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              this.rows += this.$utils.pagination(res.content, this.size);
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    filter() {
      this.currentePage = 1;
      this.rows = 20;
      this.list = [{ page: 0, itens: [] }];
      this.getRecords(this.currentePage);
    }
  },
};
</script>

<style scoped>
.badge-secondary {
  background-color: white;
}
</style>